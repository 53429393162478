/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "bebasregular";
  src: url("./fonts/BebasNeue-Regular.woff2") format("woff2"), url("./fonts/BebasNeue-Regular.woff") format("woff"), url("./fonts/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Smartphones (portrait and landscape) ----------- */
/*
P - portrait
L- landscape

devices breakpoints sneak-peak:
https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints

technique:
https://css-tricks.com/media-queries-sass-3-2-and-codekit/

usage example:

.class {
	@include breakpoint(phone-l) { width: 60%; }
	@include breakpoint(ipad-three-less) { width: 60%; }
	@include breakpoint(xs-screen) { width: 60%; }
}

*/
.boxed {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1345px;
  padding: 0 20px;
}

.full-width {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.text-al {
  text-align: left;
}

.text-ar {
  text-align: right;
}

.bold {
  font-weight: 600;
}

.col {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
}

.col-6 {
  width: 50%;
}

.col-3 {
  width: 25%;
}

.col-12 {
  width: 100%;
}

.desktop {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .desktop {
    display: block;
  }
  .desktop.inline-block {
    display: inline-block;
  }
}

.mobile {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .mobile {
    display: block;
  }
  .mobile.inline-block {
    display: inline-block;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }
  .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-1 {
    margin-left: 8.333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.667%;
  }
  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }
  .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-1 {
    margin-left: 8.333%;
  }
  .col-md-offset-2 {
    margin-left: 16.667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.333%;
  }
  .col-md-offset-5 {
    margin-left: 41.667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.333%;
  }
  .col-md-offset-8 {
    margin-left: 66.667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.333%;
  }
  .col-md-offset-11 {
    margin-left: 91.667%;
  }
  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }
  .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-1 {
    margin-left: 8.333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.667%;
  }
  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@-webkit-keyframes shake-ie {
  0%, 100% {
    margin-top: -20px;
  }
  50% {
    margin-top: 0;
  }
}
@keyframes shake-ie {
  0%, 100% {
    margin-top: -20px;
  }
  50% {
    margin-top: 0;
  }
}
@keyframes slideUp {
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes rollOverUp {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 0 -38px;
  }
  51% {
    display: none;
    background-position: 0 38px;
  }
  52% {
    display: block;
    background-position: 0 38px;
  }
  100% {
    background-position: 0 0;
  }
}
.fade-to-black {
  background-color: #000000 !important;
  color: #ffffff !important;
  -webkit-transition: all 0.54s ease-in;
  -moz-transition: all 0.54s ease-in;
  -ms-transition: all 0.54s ease-in;
  -o-transition: all 0.54s ease-in;
  transition: all 0.54s ease-in;
}
.fade-to-black a {
  color: #ffffff !important;
  -webkit-transition: all 0.54s ease-in;
  -moz-transition: all 0.54s ease-in;
  -ms-transition: all 0.54s ease-in;
  -o-transition: all 0.54s ease-in;
  transition: all 0.54s ease-in;
}
.fade-to-black h3, .fade-to-black ul, .fade-to-black p {
  background-color: #000000 !important;
  color: #ffffff !important;
  -webkit-transition: all 0.54s ease-in;
  -moz-transition: all 0.54s ease-in;
  -ms-transition: all 0.54s ease-in;
  -o-transition: all 0.54s ease-in;
  transition: all 0.54s ease-in;
}

.App .content .section.zespol article section.top p.slide-in-left {
  margin-left: 0;
  padding-left: 0;
  opacity: 1;
  -webkit-transition: all 0.54s ease-in-out;
  -moz-transition: all 0.54s ease-in-out;
  -ms-transition: all 0.54s ease-in-out;
  -o-transition: all 0.54s ease-in-out;
  transition: all 0.54s ease-in-out;
}

.App .content .section.zespol article section.top figure.slide-in-right {
  padding-left: 0;
  opacity: 1;
  -webkit-transition: all 0.54s ease-in-out;
  -moz-transition: all 0.54s ease-in-out;
  -ms-transition: all 0.54s ease-in-out;
  -o-transition: all 0.54s ease-in-out;
  transition: all 0.54s ease-in-out;
}

@keyframes slide-in-left {}
.falka-animate {
  display: block;
  position: relative;
  overflow: hidden;
}
.falka-animate svg {
  transform-origin: top right;
  transform: rotate3d(0, 0, 1, 90deg);
  display: block;
}
.falka-animate.rotate svg {
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition: transform 0.75s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out;
  -ms-transition: transform 0.75s ease-in-out;
  -o-transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
}

.falka-animate-backwards {
  display: block;
  position: relative;
  overflow: hidden;
}
.falka-animate-backwards svg {
  transform-origin: top right;
  transform: rotate3d(0, 0, 1, -90deg);
  display: block;
}
.falka-animate-backwards.rotate svg {
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition: transform 0.75s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out;
  -ms-transition: transform 0.75s ease-in-out;
  -o-transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
}

.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after, .slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
} 
/* Smartphones (portrait and landscape) ----------- */
/*
P - portrait
L- landscape

devices breakpoints sneak-peak:
https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints

technique:
https://css-tricks.com/media-queries-sass-3-2-and-codekit/

usage example:

.class {
	@include breakpoint(phone-l) { width: 60%; }
	@include breakpoint(ipad-three-less) { width: 60%; }
}

*/
body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-weight: 300;
  height: auto !important;
  /* Smartphones (portrait and landscape) ----------- */
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body {
    font-size: 14px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  body {
    font-size: 14px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: #000;
}

.overlay-loader {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10000;
  color: #000;
}
.overlay-loader .el {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.overlay-loader .el svg {
  display: block;
  float: left;
  width: 32px;
  height: 40px;
  transform: rotate(-90deg);
}

.App {
  overflow: hidden;
  display: block;
}
.App .menu-trigger {
  display: block;
  float: left;
  width: 100%;
  height: 1px;
}
.App .bar-trigger {
  position: fixed;
  right: 0;
  top: 0;
  display: block;
  z-index: 9998;
  width: 20px;
  height: 100vw;
  background-color: transparent;
}
.App > .bar {
  position: fixed;
  right: 50px;
  top: 43%;
  display: block;
  z-index: 9;
  opacity: 1;
}
.App > .bar[data-socialbar-expanded=expanded] {
  opacity: 1;
  right: 50px;
  -webkit-transition: all 0.65s ease-out;
  -moz-transition: all 0.65s ease-out;
  -ms-transition: all 0.65s ease-out;
  -o-transition: all 0.65s ease-out;
  transition: all 0.65s ease-out;
}
.App > .bar[data-socialbar-expanded=collapsed] {
  opacity: 0.35;
  right: -30px;
  -webkit-transition: all 0.65s ease-out;
  -moz-transition: all 0.65s ease-out;
  -ms-transition: all 0.65s ease-out;
  -o-transition: all 0.65s ease-out;
  transition: all 0.65s ease-out;
}
.App > .bar > .social-media {
  display: block;
}
.App > .bar > .social-media li {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 0;
  margin-bottom: 34px;
  background-size: 100%;
  background-repeat: repeat-x;
  background-position: 0 0;
}
.App > .bar > .social-media li a svg {
  display: block;
  width: 100%;
  height: auto;
}
.App > .bar > .social-media li a svg:hover path {
  fill: #ee736c;
  -webkit-transition: fill 0.18s ease-in-out;
  -moz-transition: fill 0.18s ease-in-out;
  -ms-transition: fill 0.18s ease-in-out;
  -o-transition: fill 0.18s ease-in-out;
  transition: fill 0.18s ease-in-out;
}
.App[location=app-dynamodynamo-post] > .bar {
  top: 26%;
}
.App .scroll-to-top {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  -webkit-animation-name: shake;
  animation-name: shake;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  z-index: 197;
}
.App .scroll-to-top svg {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.App .header {
  position: absolute;
  padding-top: 50px;
  text-align: right;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 97;
  width: 100%;
  box-sizing: border-box;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .App .header {
    position: fixed;
    padding-left: 65px;
    background-color: transparent;
  }
}
.App .header figure {
  float: left;
  height: auto;
  margin: 0;
  padding: 0;
}
.App .header figure.desktop {
  width: 114px;
  position: relative;
  padding-top: 9px;
}
.App .header figure.desktop .logo-mask {
  display: block;
  width: 100%;
  max-width: 19px;
  overflow: hidden;
}
.App .header figure.desktop:hover .logo-mask {
  -webkit-transition: max-width 0.21s ease-out;
  -moz-transition: max-width 0.21s ease-out;
  -ms-transition: max-width 0.21s ease-out;
  -o-transition: max-width 0.21s ease-out;
  transition: max-width 0.21s ease-out;
  max-width: 114px;
}
.App .header figure.mobile {
  width: 100%;
}
.App .header figure.mobile svg {
  display: block;
  width: 47px;
  height: 38px;
  transform: rotate(-90deg);
}
.App .content {
  padding: 114px 0;
}
.App .content .section {
  display: block;
  position: relative;
  padding-bottom: 120px;
  padding-top: 30px;
  box-sizing: border-box;
  width: 100%;
  float: left;
  min-height: 450px;
}
.App .content .section + .separator div[class*=animate] {
  width: 104px;
  height: 104px;
  overflow: hidden;
  display: inline-block;
}
.App .content .section.o-nas {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.o-nas .page-content_wrapper {
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding: 30vh 0 0;
  display: flex;
  flex-flow: column;
}
@media (min-width: 1200px) {
  .App .content .section.o-nas .page-content_wrapper {
    padding: calc(50vh - 128px) 0 0;
  }
}
.App .content .section.o-nas .container-fluid.o-nas-fluid {
  padding-top: 1vh;
}
.App .content .section.o-nas .container-fluid.introduction_wrapper {
  padding-top: 33vh;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .App .content .section.o-nas .container-fluid.introduction_wrapper {
    padding-top: 20vh;
  }
}
.App .content .section.o-nas .container-fluid.page-text-content {
  padding-top: 4em;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .App .content .section.o-nas .container-fluid.page-text-content {
    padding-top: 4em;
    padding-left: 2em;
    padding-right: 2em;
  }
}
.App .content .section.o-nas .container-fluid.page-text-divider {
  padding-top: 12vh;
}
.App .content .section.o-nas .container-fluid.page-text-divider svg {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
.App .content .section.o-nas .container-fluid figure {
  margin: 0;
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
}
.App .content .section.o-nas .container-fluid figure img {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
.App .content .section.o-nas .image-opener {
  position: absolute !important;
  top: 0;
  left: 0;
  overflow: hidden;
}
.App .content .section.o-nas .image-opener picture {
  position: relative;
  display: block;
  height: 100%;
}
.App .content .section.o-nas .image-opener picture img {
  width: 100%;
}
@supports (object-fit: cover) {
  .App .content .section.o-nas .image-opener picture img {
    height: 100%;
    object-fit: cover;
  }
}
.App .content .section.o-nas .image-opener mask {
  overflow: hidden;
}
.App .content .section.o-nas .image-opener.texture-1 {
  top: 0;
  z-index: 3;
  right: auto;
  left: auto;
  width: 150vw;
  height: auto;
}
@media (min-width: 768px) {
  .App .content .section.o-nas .image-opener.texture-1 {
    top: 38vh;
    left: auto;
    right: 0;
    width: 49vw;
    max-width: 938px;
  }
}
.App .content .section.o-nas .image-opener.texture-1 div, .App .content .section.o-nas .image-opener.texture-1 img {
  height: auto;
}
.App .content .section.o-nas .image-opener.texture-1 .mask {
  width: 100vw;
  max-width: 1600px;
  height: 49vw;
}
.App .content .section.o-nas .image-opener.texture-1 .mask .js-animate {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 49vw;
  width: auto;
}
@media (min-width: 768px) {
  .App .content .section.o-nas .image-opener.texture-1 .mask {
    width: 49vw;
    max-width: 938px;
    height: 26vw;
  }
  .App .content .section.o-nas .image-opener.texture-1 .mask .js-animate {
    height: 34vw;
  }
}
@media (min-width: 1920px) {
  .App .content .section.o-nas .image-opener.texture-1 .mask {
    width: 40vw;
    height: 21vw;
  }
  .App .content .section.o-nas .image-opener.texture-1 .mask .js-animate {
    height: 29vw;
  }
}
.App .content .section.o-nas.dds .container-fluid figure {
  width: 40%;
}
@media (min-width: 768px) {
  .App .content .section.o-nas.dds .image-opener.texture-1 {
    max-width: 1176px;
  }
}
.App .content .section.o-nas.dds .image-opener.texture-1 .mask {
  max-width: 1176px;
}
@media (min-width: 1920px) {
  .App .content .section.o-nas.dds {
    margin-bottom: 120px;
  }
}
.App .content .section.o-nas.o-nas-home .page-content_wrapper {
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding: 30vh 0 0;
  display: flex;
  flex-flow: column;
}
@media (min-width: 1200px) {
  .App .content .section.o-nas.o-nas-home .page-content_wrapper {
    padding: calc(50vh - 128px) 0 0;
  }
}
.App .content .section.o-nas.o-nas-home .container-fluid.o-nas-fluid {
  padding-top: 1vh;
}
.App .content .section.o-nas.o-nas-home .container-fluid.introduction_wrapper {
  padding-top: 19vh;
}
@media (min-width: 768px) {
  .App .content .section.o-nas.o-nas-home .container-fluid.introduction_wrapper {
    padding-top: 30vh;
  }
}
.App .content .section.o-nas.o-nas-home .container-fluid.page-text-content {
  padding-top: 4em;
}
@media (min-width: 768px) {
  .App .content .section.o-nas.o-nas-home .container-fluid.page-text-content {
    padding-top: 4em;
  }
}
.App .content .section.o-nas.o-nas-home .container-fluid.page-text-divider {
  padding-top: 12vh;
}
.App .content .section.o-nas.o-nas-home .container-fluid.page-text-divider svg {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
.App .content .section.o-nas.o-nas-home .container-fluid figure {
  margin: 0;
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
}
.App .content .section.o-nas.o-nas-home .container-fluid figure img {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
.App .content .section.o-nas.o-nas-home .image-opener {
  position: absolute !important;
  top: 0;
  left: 0;
  overflow: hidden;
}
.App .content .section.o-nas.o-nas-home .image-opener picture {
  position: relative;
  display: block;
  height: 100%;
}
.App .content .section.o-nas.o-nas-home .image-opener picture img {
  width: 100%;
}
@supports (object-fit: cover) {
  .App .content .section.o-nas.o-nas-home .image-opener picture img {
    height: 100%;
    object-fit: cover;
  }
}
.App .content .section.o-nas.o-nas-home .image-opener mask {
  overflow: hidden;
}
.App .content .section.o-nas.o-nas-home .image-opener.texture-1 {
  top: 0;
  z-index: 3;
  right: 0;
  left: auto;
  width: 100vw;
  height: 54vw;
}
@media (min-width: 768px) {
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 {
    top: 38vh;
    left: auto;
    height: 26vw;
    right: 0;
    width: 49vw;
    max-width: 938px;
  }
}
@media (min-width: 1920px) {
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 {
    width: 40vw;
    height: 21vw;
  }
}
.App .content .section.o-nas.o-nas-home .image-opener.texture-1 div, .App .content .section.o-nas.o-nas-home .image-opener.texture-1 img {
  height: auto;
}
.App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask {
  width: 100vw;
  max-width: none;
  height: 54vw;
}
@media (min-width: 768px) {
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask {
    width: 49vw;
    max-width: 938px;
    height: 26vw;
  }
}
@media (min-width: 1920px) {
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask {
    width: 40vw;
    height: 21vw;
  }
}
.App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask .js-animate {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 54vw;
  width: auto;
}
@media (min-width: 768px) {
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask .js-animate {
    height: 26vw;
  }
}
@media (min-width: 1920px) {
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask .js-animate {
    width: 40vw;
    height: 21vw;
  }
}
@media (min-width: 1920px) {
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask {
    width: 40vw;
    height: 21vw;
  }
  .App .content .section.o-nas.o-nas-home .image-opener.texture-1 .mask .js-animate {
    height: 29vw;
  }
}
@media only screen and (max-width: 768px) {
  .App .content .section.o-nas {
    padding-bottom: 0;
  }
}
.App .content .section.dds {
  padding-bottom: 30px;
  padding-top: 80px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .App .content .section.dds {
    padding-bottom: 110px;
  }
}
.App .content .section.dds .txt {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.dds .txt > figure {
  padding-left: 16%;
}
.App .content .section.dds .txt > figure svg {
  transform: rotate(-90deg);
  display: block;
  width: 254px;
  height: 311px;
}
@media only screen and (max-width: 768px) {
  .App .content .section.dds .txt {
    margin-top: 0;
    padding-top: 0;
  }
}
.App .content .section.dds .fig {
  bottom: auto;
  top: 35px;
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.dds .fig img {
  height: auto;
  max-width: 803px;
}
@media only screen and (max-width: 768px) {
  .App .content .section.dds .fig {
    position: static;
  }
}
.App .content .section.dds.slider-section .slick-slider {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}
.App .content .section.dds.slider-section .slick-slider .slick-arrow {
  position: absolute;
  z-index: 4;
  top: calc(50% - 14px);
}
.App .content .section.dds.slider-section .slick-slider .slick-arrow.slick-next {
  right: 82px;
  transform: rotate(180deg);
}
.App .content .section.dds.slider-section .slick-slider .slick-arrow.slick-prev {
  left: 28px;
}
.App .content .section.dds.slider-section .slick-slider .slick-dots {
  margin-top: 20px;
  padding-left: 51px;
}
.App .content .section.dds.slider-section .slick-slider .slick-dots li {
  display: inline-block;
  padding-top: 28px;
  position: relative;
}
.App .content .section.dds.slider-section .slick-slider .slick-dots li button {
  font-family: "bebasregular", sans-serif;
  font-size: 28px;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;
  background: none;
  outline: none;
  border: none;
  -webkit-appearance: none;
}
.App .content .section.dds.slider-section .slick-slider .slick-dots li button::before {
  content: "0";
}
.App .content .section.dds.slider-section .slick-slider .slick-dots li button::after {
  content: "";
  display: block;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: calc(50% - 9px);
  box-sizing: border-box;
}
.App .content .section.dds.slider-section .slick-slider .slick-dots li.slick-active button::after {
  content: "";
  background-color: #000;
}
.App .content .section.dds.slider-section .col {
  position: relative;
}
.App .content .section.dds.slider-section .col figure .cover {
  position: relative;
  z-index: 0;
  opacity: 1;
  width: 100%;
  height: auto;
}
.App .content .section.dds.slider-section .col figure .diagram {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.App .content .section.dds.slider-section .col figure.double .diagram {
  display: block;
  opacity: 0;
}
.App .content .section.dds.slider-section .col figure.double:hover .diagram {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.App .content .section.dds.slider-section .col figure.double:hover .cover {
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  -ms-transition: opacity 0.1s ease-in-out;
  -o-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}
.App .content .section.dds.slider-section .col figcaption {
  position: absolute;
  top: 30px;
  right: -130px;
  z-index: 3;
}
.App .content .section.dds.slider-section .col figcaption img {
  width: 167px;
  height: auto;
  display: block;
}
.App .content .section.dds.slider-section .col header {
  font-size: 28px;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
  margin-bottom: 20px;
}
.App .content .section.dds.slider-section .col article {
  width: 256px;
}
.App .content .section.dds.slider-section .mobile {
  margin-bottom: 85px;
}
.App .content .section.dds.slider-section .mobile:last-child {
  margin-bottom: 0;
}
.App .content .section.dds.slider-section .mobile .col {
  margin-top: 0;
  padding-left: 30px;
  padding-right: 30px;
}
.App .content .section.dds.slider-section .mobile .col header {
  font-size: 35px;
  line-height: 35px;
}
.App .content .section.dds.slider-section .mobile .col header p {
  word-spacing: 9999px;
}
.App .content .section.dds.slider-section .mobile .col figure .diagram {
  position: relative;
  height: auto;
  opacity: 1;
  display: none;
}
.App .content .section.dds.slider-section .mobile .col figure.double .diagram {
  display: block;
}
.App .content .section.dds.slider-section .mobile .col figcaption {
  display: none;
  right: 36px;
  top: -85px;
}
.App .content .section.reel {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.reel .reel-cover {
  position: relative;
  cursor: pointer;
}
.App .content .section.reel img {
  display: block;
  float: left;
  width: 100%;
  height: auto;
}
.App .content .section.reel h3 {
  word-spacing: 9999px;
  font-size: 58px;
  line-height: 66px;
  font-family: "bebasregular", sans-serif;
  text-transform: uppercase;
  margin-top: -8px;
}
.App .content .section.reel p {
  word-spacing: 9999px;
  font-size: 12px;
  line-height: 18px;
}
.App .content .section.reel .line {
  display: flex;
  margin: 0 auto;
  height: 2px;
  background-color: #000;
  width: 0;
}
.App .content .section.reel .line.stretched {
  width: 80%;
  -webkit-transition: width 0.45s ease-in-out;
  -moz-transition: width 0.45s ease-in-out;
  -ms-transition: width 0.45s ease-in-out;
  -o-transition: width 0.45s ease-in-out;
  transition: width 0.45s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .App .content .section.reel {
    padding-bottom: 0;
  }
  .App .content .section.reel::before {
    content: "";
    display: block;
    width: 2px;
    height: 80px;
    background-color: #000;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26px;
  }
  .App .content .section.reel .description {
    padding: 20px 60px 0;
  }
  .App .content .section.reel h3 {
    font-size: 48px;
    line-height: 1;
    margin-left: 14px;
    margin-bottom: 10px;
  }
  .App .content .section.reel img {
    width: calc(100% - 4em - 42px);
    margin-left: calc(2em + 21px);
    margin-right: calc(2em + 21px);
  }
  .App .content .section.reel .reel-cover {
    position: relative;
  }
  .App .content .section.reel .reel-cover svg {
    position: absolute;
    bottom: 16px;
    z-index: 1;
    width: 25px;
    height: 25px;
  }
  .App .content .section.reel .reel-cover svg#player_play {
    left: 70px;
  }
  .App .content .section.reel .reel-cover svg#social_vimeo {
    right: 70px;
  }
  .App .content .section.reel::after {
    content: "";
    display: block;
    width: 2px;
    height: 80px;
    background-color: #000;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26px;
  }
}
.App .content .section.zespol {
  background-color: #ffffff;
  padding: 0 21px;
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.zespol > header {
  font-size: 48px;
  line-height: 1;
  padding-left: 21px;
  margin-bottom: 10px;
  margin-top: 12px;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
}
.App .content .section.zespol article section {
  margin-left: 0;
}
.App .content .section.zespol article section.top p {
  display: none;
  margin-top: 12px;
  margin-bottom: 30px;
  margin-left: -180px;
  opacity: 0.2;
}
.App .content .section.zespol article section.top figure {
  display: block;
  width: 100%;
  margin: 0;
  padding-left: 180px;
  opacity: 0.2;
}
.App .content .section.zespol article section.top figure img {
  width: 100%;
  height: auto;
}
.App .content .section.zespol article section.btm .wave svg {
  width: 55px;
  height: auto;
}
.App .content .section.zespol article section.btm ul.team-members {
  overflow: hidden;
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.zespol article section.btm ul.team-members li {
  display: block;
  padding: 32px 18px 0px;
}
.App .content .section.zespol article section.btm ul.team-members li p {
  line-height: 1;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
}
.App .content .section.zespol article section.btm ul.team-members li p span {
  display: block;
  text-transform: lowercase;
  font-family: "Roboto", sans-serif;
}
@media only screen and (max-width: 768px) {
  .App .content .section.zespol article section.btm ul.team-members {
    padding-left: 0;
    max-height: 290px;
  }
  .App .content .section.zespol article section.btm ul.team-members li p {
    font-size: 18px;
  }
  .App .content .section.zespol article section.btm ul.team-members li p span {
    font-size: 16px;
  }
}
.App .content .section.zespol article section.btm .team-members {
  max-height: 260px;
  overflow: hidden;
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.zespol article section.btm .team-members > div {
  display: block;
  padding: 32px 18px 0px;
  outline: none !important;
}
.App .content .section.zespol article section.btm .team-members > div * {
  outline: none !important;
}
.App .content .section.zespol article section.btm .team-members > div p {
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
}
.App .content .section.zespol article section.btm .team-members > div p span {
  display: block;
  text-transform: lowercase;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}
.App .content .section.zespol article section.btm .team-members .slick-list {
  padding-bottom: 30px;
}
.App .content .section.zespol article section.btm .team-members[expanded=true] {
  -webkit-transition: max-height 1s ease-in;
  -moz-transition: max-height 1s ease-in;
  -ms-transition: max-height 1s ease-in;
  -o-transition: max-height 1s ease-in;
  transition: max-height 1s ease-in;
  max-height: 2000px;
}
@media only screen and (max-width: 768px) {
  .App .content .section.zespol article section.btm .team-members {
    padding-left: 0;
    max-height: 290px;
  }
  .App .content .section.zespol article section.btm .team-members li p {
    font-size: 18px;
  }
  .App .content .section.zespol article section.btm .team-members li p span {
    font-size: 16px;
  }
}
@media only screen and (min-width: 769px) {
  .App .content .section.zespol > header {
    font-size: 58px;
    line-height: 1;
    padding-left: 190px;
  }
  .App .content .section.zespol article section.top {
    margin-left: 23%;
  }
  .App .content .section.zespol article section.top p {
    display: block;
  }
  .App .content .section.zespol article section.top figure {
    display: block;
    width: 100%;
    margin: 0;
  }
  .App .content .section.zespol article section.top figure img {
    width: 100%;
    height: auto;
  }
  .App .content .section.zespol article section.btm {
    min-height: 300px;
  }
  .App .content .section.zespol article section.btm figure {
    padding: 0;
  }
  .App .content .section.zespol article section.btm .wave {
    width: 105px;
    height: 105px;
    overflow: hidden;
    display: inline-flex;
    margin-top: 50px;
  }
  .App .content .section.zespol article section.btm .wave svg {
    width: 105px;
  }
  .App .content .section.zespol article section.btm .team-members {
    max-height: 99px;
    padding: 0;
    margin-top: 50px;
    position: relative;
  }
  .App .content .section.zespol article section.btm .team-members .slick-arrow {
    cursor: pointer;
    position: absolute;
    top: 5px;
    display: block;
    width: 22px;
  }
  .App .content .section.zespol article section.btm .team-members .slick-arrow.slick-prev {
    left: 0;
    transform: rotate(180deg);
  }
  .App .content .section.zespol article section.btm .team-members .slick-arrow.slick-next {
    right: 20px;
  }
  .App .content .section.zespol article section.btm .team-members > div {
    padding: 0;
    /* Smartphones (portrait and landscape) ----------- */
  }
  .App .content .section.zespol article section.btm .team-members > div:first-child {
    padding-left: 48px;
  }
  .App .content .section.zespol article section.btm .team-members > div.switch {
    text-decoration: underline;
    cursor: pointer;
  }
  .App .content .section.zespol article section.btm .team-members > div p {
    font-size: 21px;
  }
  .App .content .section.zespol article section.btm .team-members > div p span {
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 769px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 769px) and (min-resolution: 192dpi) {
  .App .content .section.zespol article section.btm .team-members > div p {
    font-size: 19px;
  }
  .App .content .section.zespol article section.btm .team-members > div p span {
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 769px) and (min-width: 769px) and (max-width: 1280px) {
  .App .content .section.zespol article section.btm .team-members > div p {
    font-size: 19px;
  }
  .App .content .section.zespol article section.btm .team-members > div p span {
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
  }
}
.App .content .section.portfolio {
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.portfolio > header {
  font-size: 48px;
  line-height: 1;
  padding-left: 21px;
  margin-bottom: 120px;
  margin-top: 12px;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
  word-spacing: 9999px;
}
@media only screen and (min-width: 769px) {
  .App .content .section.portfolio > header {
    font-size: 58px;
    line-height: 1;
    padding-right: 190px;
    margin-bottom: 136px;
    text-align: right;
  }
}
.App .content .section.portfolio .years {
  text-align: center;
  margin-bottom: 47px;
}
.App .content .section.portfolio .years li {
  display: inline-block;
  cursor: pointer;
  font-family: "bebasregular", sans-serif;
  font-size: 21px;
  line-height: 1;
  padding-right: 12px;
  margin-bottom: 9px;
  color: #000;
}
.App .content .section.portfolio .years li::after {
  content: "|";
  display: inline-block;
  padding-left: 12px;
}
.App .content .section.portfolio .years li:last-child::after {
  display: none;
}
.App .content .section.portfolio .years li:hover {
  color: #ee736c;
  -webkit-transition: color 0.12s ease-in-out;
  -moz-transition: color 0.12s ease-in-out;
  -ms-transition: color 0.12s ease-in-out;
  -o-transition: color 0.12s ease-in-out;
  transition: color 0.12s ease-in-out;
}
.App .content .section.portfolio .years li:hover::after {
  color: #000;
}
.App .content .section.portfolio .years li.active {
  color: #ee736c;
  text-decoration: underline;
}
.App .content .section.portfolio nav li {
  cursor: pointer;
}
.App .content .section.portfolio.alternatesides {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.portfolio.alternatesides nav ul li {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .App .content .section.portfolio.alternatesides nav ul li {
    width: 100%;
    display: block;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper {
    width: 100%;
    margin: 0 !important;
    float: left;
    padding: 0 !important;
    position: relative;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figure {
    padding-top: 0 !important;
    overflow: hidden;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figure img {
    width: calc(100% + 60px);
    margin-left: -30px;
    height: auto;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption {
    float: right !important;
    display: block;
    width: 100%;
    position: static;
    top: auto;
    bottom: auto;
    left: auto !important;
    margin-right: 0;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption .details {
    float: right;
    display: block;
    margin-left: auto;
    margin-right: 30px;
    width: calc(50% - 30px);
    border-left: 2px solid #000;
    margin-bottom: 30px;
    padding-left: 21px;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption h3 {
    font-size: 36px;
    margin-top: 50px;
    text-align: center;
    line-height: 1;
    font-family: "bebasregular", sans-serif;
    margin-bottom: 7px;
    width: 100%;
    position: relative;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption h4 {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption p {
    float: right;
    width: 100%;
    margin-right: 2%;
  }
  .App .content .section.portfolio.alternatesides nav ul li:nth-child(odd) figcaption {
    padding-right: 0;
  }
  .App .content .section.portfolio.alternatesides nav ul li:nth-child(even) figcaption {
    padding-left: 0;
  }
}
@media only screen and (min-width: 769px) {
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    padding-right: 120px;
    padding-left: 0;
    position: relative;
    padding-top: 0;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figure {
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figure img {
    width: calc(100% + 80px);
    height: auto;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption {
    display: block;
    width: 194px;
    position: absolute;
    top: -88px;
    bottom: auto;
    margin-right: 2%;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption > div {
    width: 100%;
    height: 100%;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption h3 {
    font-size: 58px;
    line-height: 1;
    font-family: "bebasregular", sans-serif;
    margin-bottom: 30px;
    width: calc(100% + 62px);
    position: relative;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption h3 span {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption h4 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption p {
    white-space: nowrap;
    display: none;
  }
  .App .content .section.portfolio.alternatesides nav ul li:nth-child(odd) figcaption {
    right: -2px;
    left: auto;
    padding-right: 62px;
  }
  .App .content .section.portfolio.alternatesides nav ul li:nth-child(even) .portfolio-content-wrapper {
    padding-right: 0;
    padding-left: 120px;
    margin-top: 39%;
  }
  .App .content .section.portfolio.alternatesides nav ul li:nth-child(even) figcaption {
    left: -2px;
    right: auto;
    padding-left: 62px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption {
    top: -79px;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption h3 {
    font-size: 40px;
    margin-bottom: 10px;
    margin-bottom: 40px;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption {
    top: -79px;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption h3 {
    font-size: 40px;
    margin-bottom: 10px;
    margin-bottom: 40px;
  }
  .App .content .section.portfolio.alternatesides nav ul li .portfolio-content-wrapper figcaption p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 769px) {
  .App .content .section.portfolio.alternatesides::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 168px;
    left: calc(50% - 1px);
    height: calc(100% - 382px);
    width: 1px;
    background-color: #000;
  }
}
.App .content .section.portfolio.tiles {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.portfolio.tiles nav ul li {
  display: inline-block;
  position: relative;
}
.App .content .section.portfolio.tiles nav ul li::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.App .content .section.portfolio.tiles nav ul li:hover::after {
  background-color: rgba(238, 115, 108, 0.6);
  -webkit-transition: background-color 0.18s ease-in-out;
  -moz-transition: background-color 0.18s ease-in-out;
  -ms-transition: background-color 0.18s ease-in-out;
  -o-transition: background-color 0.18s ease-in-out;
  transition: background-color 0.18s ease-in-out;
}
.App .content .section.portfolio.tiles nav ul .col {
  padding: 0;
}
.App .content .section.portfolio.tiles nav ul figure {
  float: left;
  width: 100%;
  display: block;
  background-position: top left;
  background-size: 100%;
  background-repeat: no-repeat;
}
.App .content .section.portfolio.tiles nav ul figure img {
  float: left;
  width: 100%;
  height: auto;
}
.App .content .section.portfolio.tiles nav ul figcaption {
  position: absolute;
  left: -200px;
  opacity: 0;
  bottom: 10px;
  z-index: 2;
  color: #ffffff;
  width: 100%;
}
.App .content .section.portfolio.tiles nav ul figcaption .row {
  margin: 12px;
}
.App .content .section.portfolio.tiles nav ul figcaption h3 {
  font-size: 23px;
  line-height: 28px;
  font-family: "bebasregular", sans-serif;
  text-transform: uppercase;
  text-decoration: underline;
}
.App .content .section.portfolio.tiles nav ul figcaption h4 {
  font-size: 12px;
}
.App .content .section.portfolio.tiles nav ul li {
  overflow: hidden;
}
.App .content .section.portfolio.tiles nav ul li:hover figure {
  background-size: calc(100% + 60px);
  background-position: -30px -30px;
  -webkit-transition: all 0.12s ease-in;
  -moz-transition: all 0.12s ease-in;
  -ms-transition: all 0.12s ease-in;
  -o-transition: all 0.12s ease-in;
  transition: all 0.12s ease-in;
}
.App .content .section.portfolio.tiles nav ul li:hover figcaption {
  left: 27px;
  opacity: 1;
  -webkit-transition: all 0.26s ease-out;
  -moz-transition: all 0.26s ease-out;
  -ms-transition: all 0.26s ease-out;
  -o-transition: all 0.26s ease-out;
  transition: all 0.26s ease-out;
}
.App .content .section.portfolio .more {
  text-align: center;
  margin-top: 22px;
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.portfolio .more figure svg {
  width: 32px;
  height: auto;
  transform: rotate(-90deg);
}
.App .content .section.portfolio .more p {
  font-size: 14px;
  text-transform: lowercase;
}
.App .content .section.portfolio .more:hover p {
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .App .content .section.portfolio .more {
    display: block;
    padding-right: 38px;
    padding-top: 30px;
  }
  .App .content .section.portfolio .more figure {
    display: none;
  }
  .App .content .section.portfolio .more p {
    font-size: 18px;
    text-decoration: underline;
    text-transform: capitalize;
  }
}
.App .content .section.kontakt {
  padding-bottom: 30px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content .section.kontakt > header {
  font-size: 48px;
  line-height: 1;
  padding-left: 49px;
  margin-bottom: 29px;
  margin-top: 12px;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
}
@media only screen and (min-width: 769px) {
  .App .content .section.kontakt > header {
    font-size: 58px;
    line-height: 1;
    padding-left: 190px;
  }
}
.App .content .section.kontakt .cover {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: -200px;
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.App .content .section.kontakt > div img {
  width: 100%;
  height: auto;
}
.App .content .section.kontakt .parallax-outer {
  overflow: hidden;
}
.App .content .section.kontakt .parallax-outer img {
  width: 100% !important;
  height: auto !important;
}
.App .content .section.kontakt article {
  padding: 66px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: #fff;
  color: #000;
  margin-top: -20px;
  position: relative;
}
.App .content .section.kontakt article .logo {
  margin-bottom: 66px;
}
.App .content .section.kontakt article .logo svg {
  width: 54px;
  height: auto;
  transform: rotate(-90deg);
}
.App .content .section.kontakt article .col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-bottom: 168px;
  padding-right: 0;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .App .content .section.kontakt article .col {
    flex: 1;
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .App .content .section.kontakt article .col::after {
    content: "";
    display: block;
    height: 94px;
    width: 2px;
    background-color: #ffffff;
    position: absolute;
    bottom: 39px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .App .content .section.kontakt article .col:last-child::after {
    display: none;
  }
}
.App .content .section.kontakt article .col h3 {
  display: block;
  line-height: 1;
  padding-bottom: 9px;
  font-size: 14px;
  text-transform: lowercase;
  width: 100%;
}
.App .content .section.kontakt article .col a, .App .content .section.kontakt article .col address, .App .content .section.kontakt article .col p {
  display: block;
  font-size: 28px;
  line-height: 32px;
  width: 100%;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
}
.App .content .section.kontakt article .col .pdf {
  margin-top: 52px;
}
.App .content .section.kontakt article .col .pdf.hidden {
  display: none;
}
.App .content .section.kontakt article .col .pdf h3 {
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline;
  text-transform: uppercase;
}
.App .content .section.kontakt article .col .pdf a p {
  font-size: 18px;
  line-height: 33px;
  text-transform: capitalize;
}
.App .content .section.kontakt article .social-media {
  color: #fff;
  display: block;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  padding-bottom: 168px;
  margin-left: 0;
  white-space: nowrap;
}
.App .content .section.kontakt article .social-media li {
  width: 30px;
  height: 29px;
  margin: 0 21px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  background-position: 0 0;
}
.App .content .section.kontakt article .social-media li a {
  display: block;
  float: left;
  width: 100%;
  height: 100%;
}
.App .content .section.kontakt article .social-media li a svg {
  display: block;
  width: 100%;
  height: auto;
}
.App .content .section.kontakt article .social-media li a svg:hover path {
  fill: #ee736c;
  -webkit-transition: fill 0.25s ease-in-out;
  -moz-transition: fill 0.25s ease-in-out;
  -ms-transition: fill 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out;
}
.App .content .section.kontakt .pdf.mobile {
  padding: 0 50px 72px;
  margin-top: 0;
}
.App .content .section.kontakt .pdf.mobile h3 {
  font-size: 36px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
}
.App .content .section.kontakt .pdf.mobile a p {
  font-size: 18px;
  line-height: 52px;
  text-transform: capitalize;
  font-weight: 600;
}
.App .content .section.kontakt .pdf.mobile a figure {
  margin: 0 auto;
  text-align: center;
}
.App .content .section.kontakt .pdf.mobile a figure svg {
  width: 116px;
  height: 141px;
}
.App .content .section.kontakt.dds {
  padding-top: 0;
}
@media only screen and (min-width: 769px) {
  .App .content .section.kontakt article {
    padding: 20px 0 0;
  }
  .App .content .section.kontakt article::after {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 200px;
    left: calc(50% - 12px);
    z-index: 1;
    background-color: #FFF;
  }
  .App .content .section.kontakt article .col {
    padding-right: 20px;
    margin-top: 42px;
    padding-bottom: 88px;
  }
  .App .content .section.kontakt article .col h3 {
    padding: 25px 0 10px;
  }
  .App .content .section.kontakt article .col.col1 {
    text-align: right;
    padding-right: 10vw;
  }
  .App .content .section.kontakt article .col.col1 .ftr-logo {
    display: inline-block;
    margin-top: 20px;
  }
  .App .content .section.kontakt article .col.col1 .ftr-falka {
    width: 100%;
    height: 98px;
    margin-left: 12px;
    display: block;
    text-align: right;
  }
  .App .content .section.kontakt article .col.col1 .ftr-falka svg {
    display: inline-block;
    width: 106px;
    height: auto;
    margin-top: 327px;
  }
  .App .content .section.kontakt article .col.col2 {
    text-align: center;
    padding-right: 5vw;
    padding-left: 5vw;
  }
  .App .content .section.kontakt article .col.col2 h3, .App .content .section.kontakt article .col.col2 ul, .App .content .section.kontakt article .col.col2 p {
    display: block;
    position: relative;
    background-color: #FFF;
    color: #000;
    z-index: 2;
  }
  .App .content .section.kontakt article .col.col2 ul {
    padding-bottom: 0;
  }
  .App .content .section.kontakt article .col.col2 p {
    padding-bottom: 20px;
  }
  .App .content .section.kontakt article .col.col3 {
    padding-left: 10vw;
  }
  .App .content .section.kontakt article .col.col3 .newsletter {
    display: block;
    width: 100%;
    margin: 0;
    margin-top: 73px;
  }
  .App .content .section.kontakt article .col.col3 .newsletter button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: 2px solid #fff;
    font-family: "bebasregular", sans-serif;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    outline: none;
    color: #fff;
    padding: 10px 20px 9px;
    cursor: pointer;
  }
  .App .content .section.kontakt article .col.col3 .newsletter button:hover {
    text-decoration: underline;
  }
  .App .content .section.kontakt article .col.col3 .pdf {
    margin-top: 117px;
    vertical-align: top;
  }
  .App .content .section.kontakt article .col.col3 .pdf a {
    vertical-align: top;
  }
  .App .content .section.kontakt article .col.col3 .pdf a:hover p {
    text-decoration: underline;
  }
  .App .content .section.kontakt article .col.col3 .pdf p {
    display: inline-block;
    line-height: 1 !important;
    max-width: 60px;
    width: 20%;
    word-spacing: 9999px;
    vertical-align: top;
  }
  .App .content .section.kontakt article .col.col3 .pdf figure {
    display: inline-block;
    margin-left: 14px;
  }
  .App .content .section.kontakt article .social-media {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 120px;
    padding-top: 249px;
  }
  .App .content .section.kontakt article .social-media::before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 214px;
    top: 0;
    left: 50%;
    z-index: 1;
    background-color: #FFF;
  }
}
.App .content.dds-content {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content.dds-content > header {
  text-align: center;
  font-size: 46px;
  line-height: 1;
  padding-left: 24px;
  margin-bottom: 22px;
  margin-top: 12px;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif;
}
@media only screen and (min-width: 769px) {
  .App .content.dds-content > header {
    text-align: left;
    font-size: 58px;
    line-height: 1;
    padding-left: 102px;
  }
}
.App .content.dds-content section.portfolio {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content.dds-content section.portfolio.alternatesides {
  padding-bottom: 120px;
}
.App .content.dds-content section.portfolio.alternatesides > a {
  display: none;
}
.App .content.dds-content section.portfolio > header {
  text-align: center;
  font-size: 46px;
}
@media only screen and (min-width: 769px) {
  .App .content.dds-content section.portfolio > header {
    text-align: left;
    padding-left: 102px;
  }
}
.App .content.dds-content section.kontakt {
  /* Smartphones (portrait and landscape) ----------- */
}
.App .content.dds-content section.kontakt > header {
  text-align: center;
  font-size: 46px;
}
@media only screen and (min-width: 769px) {
  .App .content.dds-content section.kontakt > header {
    text-align: left;
  }
}
.App .content .separator {
  text-align: center;
  margin-top: 42px;
  margin-bottom: 36px;
  display: block;
  float: left;
  width: 100%;
}
.App .content .separator svg {
  display: inline-block;
  width: 104px;
  height: auto;
}
.App[location*=portfolio] .content section.portfolio > header {
  display: none;
}
.App .footer ul {
  text-align: center;
}
.App .footer li {
  display: inline-block;
}
.App .footer li a, .App .footer li span {
  margin: 0 20px;
  display: inline-block;
}
.App .footer li:first-child {
  margin-left: 0;
}
.App .footer li::after {
  content: "|";
  display: inline-block;
}
.App .footer li:last-child::after {
  display: none;
}
.App .footer a:hover {
  text-decoration: underline;
}

.show-arrow-up .App .scroll-to-top {
  display: block;
}

nav.menu {
  display: block;
  float: right;
  margin-top: 0;
  font-family: "bebasregular", sans-serif;
}
nav.menu .burger {
  cursor: pointer;
  position: absolute;
  right: -50px;
  top: 48px;
  opacity: 0.35;
  width: 22px;
}
nav.menu .burger svg {
  display: block;
  width: 22px;
  height: 22px;
}
nav.menu[data-menu-expanded=collapsed] {
  margin-top: -120px;
  -webkit-transition: margin-top 0.65s ease-out;
  -moz-transition: margin-top 0.65s ease-out;
  -ms-transition: margin-top 0.65s ease-out;
  -o-transition: margin-top 0.65s ease-out;
  transition: margin-top 0.65s ease-out;
}
nav.menu[data-menu-expanded=collapsed] .burger {
  right: 30px;
  opacity: 1;
  -webkit-transition: all 0.65s ease-out;
  -moz-transition: all 0.65s ease-out;
  -ms-transition: all 0.65s ease-out;
  -o-transition: all 0.65s ease-out;
  transition: all 0.65s ease-out;
}
nav.menu[data-menu-expanded=expanded] {
  margin-top: 0px;
  -webkit-transition: margin-top 0.55s ease-out;
  -moz-transition: margin-top 0.55s ease-out;
  -ms-transition: margin-top 0.55s ease-out;
  -o-transition: margin-top 0.55s ease-out;
  transition: margin-top 0.55s ease-out;
}
nav.menu[data-menu-expanded=expanded] .burger {
  right: -50px;
  opacity: 0.35;
  -webkit-transition: all 0.65s ease-out;
  -moz-transition: all 0.65s ease-out;
  -ms-transition: all 0.65s ease-out;
  -o-transition: all 0.65s ease-out;
  transition: all 0.65s ease-out;
}
nav.menu > ul {
  text-transform: uppercase;
}
nav.menu > ul > li {
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
nav.menu > ul > li::after {
  content: "";
  display: block;
  background-color: #ee736c;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
}
@media (min-width: 769px) {
  nav.menu > ul > li::after {
    display: none;
  }
}
nav.menu > ul > li div {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: -12px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 1279px) {
  nav.menu > ul > li div {
    left: 7px;
  }
}
nav.menu > ul > li ul {
  float: left;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  height: 750px;
  max-height: 0;
  overflow: hidden;
  text-align: left;
}
nav.menu > ul > li ul li {
  display: block;
  padding-right: 52px;
}
nav.menu > ul > li ul li > span {
  font-size: 18px;
  float: left;
  display: block;
  width: 100%;
  padding: 20px 12px;
  cursor: pointer;
}
nav.menu > ul > li ul li > span:hover {
  text-decoration: underline;
}
nav.menu > ul > li ul li > span span:hover {
  text-decoration: underline;
}
nav.menu > ul > li > span:hover, nav.menu > ul > li a:hover {
  text-decoration: underline;
}
nav.menu > ul > li.active::after {
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -ms-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
  width: 100%;
}
nav.menu > ul > li.active ul {
  max-height: 194px;
  -webkit-transition: max-height 0.3s ease-out;
  -moz-transition: max-height 0.3s ease-out;
  -ms-transition: max-height 0.3s ease-out;
  -o-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
}
nav.menu > ul.desktop {
  display: inline-block;
  float: right;
}
nav.menu > ul.desktop > li {
  display: inline-block;
  width: auto;
  margin: 9px 52px 12px;
  padding-bottom: 2px;
  font-size: 20px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 1279px) {
  nav.menu > ul.desktop > li {
    margin: 9px 40px 12px;
    padding: 0 0 12px;
  }
}
nav.menu > ul.mobile {
  float: left;
  width: calc(100% + 60px);
  margin-top: 0;
  padding-top: 24px;
  padding-bottom: 0;
  overflow: hidden;
  max-height: 0;
  margin-left: -30px;
}
nav.menu > ul.mobile > li {
  display: block;
  width: 100%;
  padding: 26px 0 27px;
  font-size: 39px;
  text-align: center;
}
nav.menu > ul.mobile > li:last-child {
  line-height: 1.2;
  word-spacing: 9999px;
}

.menu-close {
  position: absolute;
  z-index: 1;
  right: 40px;
  top: 56px;
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.menu-close svg {
  display: block;
  width: 100%;
  height: 100%;
}

.menu-open {
  position: absolute;
  z-index: 1;
  right: 40px;
  top: 52px;
  width: 35px;
  height: auto;
  cursor: pointer;
}
.menu-open svg {
  display: block;
  width: 100%;
  height: 100%;
}

header {
  background-color: rgba(255, 255, 255, 0);
  max-height: 134px;
}
header nav.menu > ul.mobile {
  background-color: rgba(255, 255, 255, 0);
}
header nav.lang {
  background-color: rgba(255, 255, 255, 0);
}
header nav.lang > ul {
  max-height: 0;
}
header.opened {
  background-color: rgba(255, 255, 255, 0.92);
  transition: background-color 0.1s ease-in;
}
header.opened nav.menu {
  width: 100% !important;
}
header.opened nav.menu > ul.mobile {
  padding-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.92);
  max-height: 960px;
  transition: background-color 0.1s ease-in, max-height 0.65s linear;
}
header.opened nav.menu > ul.mobile > li {
  -webkit-transform: translateY(10vh);
  transform: translateY(10vh);
  opacity: 0;
}
header.opened nav.menu > ul.mobile > li:nth-child(1) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(2) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(3) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(4) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(5) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(6) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(7) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(8) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(9) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
header.opened nav.menu > ul.mobile > li:nth-child(10) {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

nav.lang {
  display: block;
  margin-top: 0;
  padding-bottom: 0;
  float: left;
  width: calc(100% + 60px);
  margin-left: -30px;
  max-height: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
nav.lang ul {
  display: block;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  nav.lang ul {
    max-height: 27px !important;
    opacity: 1 !important;
  }
}
nav.lang ul li {
  display: inline-block;
}
nav.lang ul li a {
  display: block;
  -moz-appearance: unset;
  -ms-progress-appearance: unset;
  -webkit-appearance: unset;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  text-align: right;
  padding: 0;
  padding-top: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-family: "bebasregular", sans-serif;
  font-size: 20px;
}
nav.lang ul li:hover a {
  text-decoration: underline;
}
nav.lang ul li.active a {
  text-decoration: underline;
}
@media only screen and (min-width: 769px) {
  nav.lang {
    margin: 0;
    display: inline-block;
    margin-left: 27px;
    vertical-align: top;
    padding-top: 9px;
    float: right;
    width: auto;
  }
  nav.lang ul li {
    text-align: right;
  }
  nav.lang ul li a {
    font-size: 13px;
    padding-left: 12px;
    padding-right: 0px;
  }
}

header.opened nav.lang {
  background-color: rgba(255, 255, 255, 0.92);
  padding-top: 46px;
  padding-bottom: 30px;
  max-height: 80px;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
header.opened nav.lang > ul {
  max-height: 27px;
  opacity: 1;
  transition: all 0.2s linear;
}

.scroller {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  height: 180px;
  display: block;
  pointer-events: none;
  user-select: none;
  top: 50%;
  margin-top: -90px;
}
.scroller__label {
  display: block;
  transform: rotate(-90deg);
  font-size: 13px;
  line-height: 1;
  color: #000;
  text-align: right;
  padding-top: 9px;
}
.scroller__label figure {
  display: inline-block;
  height: 19px;
  width: 40px;
  margin-right: 48px;
  position: relative;
}
.scroller__label figure svg {
  width: auto;
  height: 19px;
  margin-right: 8px;
}
.scroller__label figure span {
  display: block;
  position: absolute;
  top: 10px;
  font-size: 8px;
  left: 34px;
}
.scroller__progress {
  display: block;
  margin-top: 11px;
}
.scroller__progress__line {
  display: block;
  width: 2px;
  background-color: #000;
  height: 100%;
  margin: 0 auto;
}
.scroller.scroller-dp {
  position: absolute;
  bottom: auto;
  margin-top: 0;
  top: -14px;
  left: calc(50% - 50px);
  height: 120%;
}
.scroller.scroller-dp__light .scroller__label {
  color: #ffffff;
}
.scroller.scroller-dp__light .scroller__progress__line {
  background-color: #ffffff;
}

.home {
  position: fixed;
  top: 52px;
  left: 38px;
  width: 18px;
  cursor: pointer;
  opacity: 1;
  z-index: 100;
  transform: rotate(-90deg);
}
.home:hover {
  opacity: 0.6;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.close-player {
  position: fixed;
  top: calc(50% + 124px);
  left: 38px;
  width: 18px;
  cursor: pointer;
  opacity: 1;
  z-index: 100;
}
.close-player:hover {
  opacity: 0.6;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.player-container {
  position: fixed;
  margin-top: 0;
  margin-left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 98;
  color: #f5f1f1;
  padding: 0;
  box-sizing: border-box;
}
.player-container header {
  position: absolute;
  bottom: 110px;
  right: 29px;
  font-family: "bebasregular", sans-serif;
  z-index: 10;
}
.player-container header h4 {
  font-size: 44px;
  line-height: 65px;
  alignment-baseline: top;
  text-transform: uppercase;
}
.player-container header ul li {
  font-size: 11px;
  line-height: 14px;
  font-family: "Roboto", sans-serif;
}
.player-container nav ul {
  position: absolute;
  width: 18px;
  z-index: 10;
  display: block;
  top: calc(50% - 50px);
  left: 38px;
}
.player-container nav ul li {
  display: block;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  opacity: 1;
}
.player-container nav ul li:hover {
  opacity: 0.6;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.player-container .stage {
  pointer-events: none;
  overflow: hidden;
  display: block;
  margin-top: 60px;
  margin-left: 80px;
  width: calc(100vw - 160px);
  height: calc(100vh - 120px);
  position: relative;
}
.player-container .stage .vid iframe {
  z-index: 9;
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.player-container .stage.mobile {
  margin: 0;
  width: 100%;
  height: 100%;
}
@media screen and (orientation: portrait) {
  .player-container .stage.mobile .vid iframe {
    z-index: 9;
    width: 126vw;
    min-width: auto;
    height: auto;
    min-height: 40vh;
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.cursor {
  position: absolute;
  transform: rotate3D(0, 0, 1, -90deg);
  z-index: 100000;
  pointer-events: none;
}

.section-title h2 {
  text-align: left;
  font-size: 58px;
  line-height: 1;
  padding: 0;
  padding-left: 124px;
  margin-bottom: 22px;
  margin-top: 12px;
  text-transform: uppercase;
  font-family: "bebasregular", sans-serif, sans-serif;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .section-title h2 {
    padding-left: 102px;
  }
}

.dp-content .o-nas {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .dp-content .o-nas {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.dp-content .o-nas .page-content_wrapper {
  padding-top: 44px !important;
}
.dp-content .o-nas h2 {
  font-family: "bebasregular", sans-serif;
  text-transform: uppercase;
  font-size: 64px;
  line-height: 60px;
  padding-bottom: 28px;
}
.dp-content .o-nas__text {
  margin-bottom: 38px;
}
.dp-content .section.portfolio {
  background-color: #000;
  padding-top: 44px;
  padding-bottom: 57px;
}
.dp-content .section.portfolio .more {
  padding-top: 27px;
}
.dp-content .portfolio__item {
  margin: 0 4.5px 9px;
  padding: 0;
  cursor: pointer;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.dp-content .portfolio__item img, .dp-content .portfolio__item video {
  width: 100%;
  height: auto;
  vertical-align: top;
  display: block;
}
.dp-content .portfolio__item .mask {
  pointer-events: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation-name: fadein;
  animation-duration: 0.13s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 768px) {
  .dp-content .portfolio__item .mask {
    display: none !important;
  }
}
.dp-content .portfolio__item figure {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  width: 64px;
  height: 64px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .dp-content .portfolio__item figure {
    width: 32px;
    height: 32px;
    opacity: 0.7 !important;
  }
}
.dp-content .portfolio__item figure svg {
  display: block;
  width: 100%;
  height: auto;
}
.dp-content .portfolio__item figure.animate {
  animation-name: fadein;
  animation-duration: 0.77s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.dp-content .portfolio__item figcaption {
  pointer-events: none;
  position: absolute;
  display: none;
  bottom: 18px;
  left: -64px;
  z-index: 1;
  color: #ffffff;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .dp-content .portfolio__item figcaption {
    display: block;
  }
}
.dp-content .portfolio__item figcaption header {
  font-size: 18px;
  line-height: 21px;
  font: "Roboto", sans-serif;
  font-weight: 300;
}
.dp-content .portfolio__item figcaption.lightmode {
  color: #000;
}
.dp-content .portfolio__item figcaption article h3, .dp-content .portfolio__item figcaption article p {
  font-size: 64px;
  line-height: 60px;
  font-family: "bebasregular", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.dp-content .portfolio__item figcaption.animate {
  display: inline-block;
  animation-name: slide-in-right;
  animation-duration: 0.33s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes slide-in-right {
  0% {
    left: -64px;
  }
  100% {
    left: -32px;
  }
}
@media only screen and (max-width: 768px) {
  .dp-content .portfolio__item figcaption {
    opacity: 0.7 !important;
    left: 30px !important;
  }
  .dp-content .portfolio__item figcaption article h3, .dp-content .portfolio__item figcaption article p {
    font-size: 36px;
    line-height: 30px;
  }
}
.dp-content .portfolio__item:hover {
  -webkit-transition: all 5s linear;
  -moz-transition: all 5s linear;
  -ms-transition: all 5s linear;
  -o-transition: all 5s linear;
  transition: all 5s linear;
  background-size: calc(100% + 60px);
  background-position: -30px -30px;
}
.dp-content .portfolio__item__grey {
  cursor: default;
}
.dp-content .portfolio__item__grey img {
  backdrop-filter: grayscale(100);
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .dp-content .portfolio__item__grey img {
    backdrop-filter: grayscale(0);
  }
}
.dp-content .portfolio__item__grey__applyEffect img {
  backdrop-filter: grayscale(0);
  transition: backdrop-filter 0.46s ease-in-out;
}
.dp-content .portfolio__item__reel {
  background-size: 100% !important;
  background-position: 0 0 !important;
}
.dp-content .portfolio__item__reel figcaption {
  /* Smartphones (portrait and landscape) ----------- */
}
.dp-content .portfolio__item__reel figcaption.animate-reel-title {
  animation-name: slide-in-right;
  animation-duration: 0.88s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes slide-in-right {
  0% {
    left: -64px;
  }
  100% {
    left: -32px;
  }
}
@media only screen and (max-width: 768px) {
  .dp-content .portfolio__item__reel figcaption {
    left: 30px !important;
  }
}
.dp-content .portfolio__item__full-width {
  background-size: 100% !important;
  background-position: 0 0 !important;
}
.dp-content .portfolio__item.col-xs-12 {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.dp-content .portfolio__item.col-sm-4 {
  -webkit-flex-basis: calc(33.333% - 9px);
  flex-basis: calc(33.333% - 9px);
  max-width: calc(33.333% - 9px);
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .dp-content .portfolio__item.col-sm-4 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
.dp-content .portfolio__scroller-holder {
  position: relative;
}
.dp-content .section.kontakt article .col::after {
  content: "";
  background-color: #000 !important;
}

.ps-content__scroller-holder {
  position: relative;
}
.ps-content__scroller-holder .scroller-dp {
  top: 34px;
}
.ps-content__lists h2 {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .ps-content__lists h2 {
    margin-left: -16px;
    padding-left: 0;
  }
}